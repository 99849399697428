import React from 'react';
import './styles/styles.scss';
import Image from '../../components/image/Image'
import SideBar from '../../components/sideBar/SideBar';

export default function Operations() {

    return (
        <>
            <div className='operations position-relative' id='operations'>
                <div className="under-title">Obszary działania</div>
                <SideBar section={2} />
                <div className='container operations__container position-relative'>
                    <h3 className='title'>Obszary działania</h3>
                    <div className='operations__section'>
                        <div className='operation'>
                            <Image src='operation1.jpg'
                                className='mx-auto shadow-xl'
                                alt='logo' />
                            <div className='operation-frame'>
                                <p>
                                    Modele inwestycji kubaturowych i&#160;inżynieryjnych dla&#160;przemysłu.
                                </p>
                            </div>
                        </div>
                        <div className='operation'>
                            <Image src='operation2.jpg'
                                className='mx-auto shadow-xl'
                                alt='logo' />
                            <div className='operation-frame'>
                                <p>
                                    Modele linii technologicznych do&#160;koordynacji rozbudowy.
                                </p>
                            </div>
                        </div>
                        <div className='operation operation--top'>
                            <Image src='operation3.png'
                                className='mx-auto shadow-xl'
                                alt='logo' />
                            <div className='operation-frame'>
                                <p>
                                    Integracja danych o&#160;budynku do&#160;różnych systemów w&#160;tym platform FM.
                                </p>
                            </div>
                        </div>
                        <div className='operation operation--top'>
                            <Image src='operation4.jpg'
                                className='mx-auto shadow-xl'
                                alt='logo' />
                            <div className='operation-frame'>
                                <p>
                                    Pomiary obiektów metodą skanowania 3D.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}