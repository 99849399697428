import React from 'react';
import './styles/styles.scss';
import Navigation from './components/navigation/Navigation';
import SideBar from '../../components/sideBar/SideBar';



export default function Header() {

    return (
        <div className='header__container position-relative'>
            <SideBar section={'header'} />
            <div className='container navigation_container'>
                <Navigation/>
                <div className='header'>
                    <div className='header__left xl-big'>
                        <p className='white'>Informacja</p>
                        <p className='blue'>i Technologia</p>
                    </div>
                    <div className='header__right'>
                        <p className='white xl-normal'>
                            Tworzymy bazy danych w modelu BIM dla Twoich potrzeb do różnych zastosowań
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}