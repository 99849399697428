import React from 'react';
import ReactDOM from 'react-dom'
import Image from '../image/Image'

class Panel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0
    };
  }

  componentDidMount() {
    window.setTimeout(() => {
      const el = ReactDOM.findDOMNode(this);
      const height = el.querySelector('.panel__inner').scrollHeight;
      this.setState({
        height
      });
    }, 333);
  }

  render() {
    const {
      title,
      subtitle,
      content,
      activeTab,
      index,
      activateTab,
      value1,
      value1description,
      value2,
      value2description,
      value3,
      value3description,
      value4,
      value4description,
      image1,
      image2,
      image3,
      score1,
      score2,
      score3,
      score4 } = this.props;

    const { height } = this.state;
    const isActive = activeTab === index;
    const innerStyle = {
      height: `${isActive ? height : 0}px`
    }

    return (
      <div className='panel'
        role='tabpanel'
        aria-expanded={isActive}>
        <button className='panel__label'
          role='tab'
          onClick={activateTab}>
          <div className="icon-panel">
            <div className="arrow"></div>
          </div>
          <p className='icon-panel-title' dangerouslySetInnerHTML={{__html: title}}></p>
          <span className='xl-small' dangerouslySetInnerHTML={{__html: subtitle}}></span>
        </button>
        <div className='panel__inner'
          style={innerStyle}
          aria-hidden={!isActive}>
          <div className='panel__content'>
            <div className='panel__content-left'>
              <div>
                <p className='text' dangerouslySetInnerHTML={{__html: content}}></p>
                <p className='list'>
                  {score1 && (
                    <p>-{score1}</p>
                  )}
                  {score2 && (
                    <p>-{score2}</p>
                  )}
                  {score3 && (
                    <p>-{score3}</p>
                  )}
                  {score4 && (
                    <p>-{score4}</p>
                  )}
                </p>
              </div>
              <div>
                <div className='panel_details'>
                  <div className='mb-15'>
                    <span className='blue d-block'>{value1}</span>
                    <span dangerouslySetInnerHTML={{__html: value1description}}></span>
                  </div>
                  <div className='mb-15'>
                    <span className='blue d-block'>{value2}</span>
                    <span dangerouslySetInnerHTML={{__html: value2description}}></span>
                  </div>
                  <div className='mb-0'>
                    <span className='blue d-block'>{value3}</span>
                    <span dangerouslySetInnerHTML={{__html: value3description}}></span>
                  </div>
                  <div className='mb-0'>
                    <span className='blue d-block'>{value4}</span>
                    <span dangerouslySetInnerHTML={{__html: value4description}}></span>
                  </div>
                </div>
              </div>
            </div>
            <div className='images'>
              <Image src={image1}
                className='mx-auto shadow-xl first-image'
                alt='logo' />
              <Image src={image2}
                className='mx-auto shadow-xl second-image'
                alt='logo' />
              <Image src={image3}
                className='mx-auto shadow-xl third-image'
                alt='logo' />
              {/* {image1 && (
								<img src={window.location.origin + image1.url}
									className='panel-image'
									alt='' />
							)}
							{image2 && (
								<img src={window.location.origin + image2.url}
									className='panel-image'
									alt='' />
							)}
							{image3 && (
								<img src={window.location.origin + image3.url}
									className='panel-image'
									alt='' />
							)} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Panel