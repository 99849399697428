import React from 'react';
import './styles/styles.scss';
import Image from '../../components/image/Image'
import SideBar from '../../components/sideBar/SideBar';

const cooperations = [
    {
        text: 'Korzystasz z naszego ogromnego doświadczenia i&#160;zyskujesz pewność, że&#160;poprowadzimy Twój projekt z sukcesem.',
        image: {
            src: 'cooperation1.svg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'Wybierzesz optymalny kierunek z&#160;dostępnych na&#160;rynku rozwiązań technologicznych.',
        image: {
            src: 'cooperation2.svg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'Otrzymujesz indywidualną usługę na&#160;najwyższym poziomie dostosowaną do&#160;potrzeb projektu.',
        image: {
            src: 'cooperation3.svg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'Dostajesz wsparcie, podczas wdrażania do&#160;użytkowania bazy danych, w&#160;postaci modeli informacyjnych.',
        image: {
            src: 'cooperation4.svg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
]
const Cooperation = () => {

    return (
        <>
            <div className='cooperations position-relative' id="cooperations">
                <SideBar section={ 3 }/>
                <div className='container  cooperations__container'>
                    <h3 className='title'>Współpracując z nami:</h3>
                    <div className='cooperations__section'>
                        {cooperations.map((cooperation, index) => {
                            return (
                                <div className='cooperation' key={index}>
                                    <div className='cooperation__top'>
                                        <Image src={cooperation.image.src}
                                            className={cooperation.image.className}
                                            alt={cooperation.image.alt} />
                                    </div>
                                    <div className='cooperation__bottom xl-small'>
                                        <p dangerouslySetInnerHTML={{__html: cooperation.text}}></p>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cooperation;