import React from 'react';
import './styles/styles.scss';
import Image from '../../components/image/Image';
import SideBar from '../../components/sideBar/SideBar';

const activities = [
    {
        text: 'Tworzymy wirtualne modele 3D&#160;BIM, a&#160;wraz z&#160;nimi bazy danych. Nasze modele mają zastosowanie w&#160;całym cyklu życia budynku, od&#160;planowania budowy po&#160;sprzedaż i&#160;eksploatację.',
        image: {
            src: 'activity1.jpg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'Efektem naszej pracy jest, przygotowany zgodnie z&#160;potrzebami klientów model budynku z&#160;zasobem informacji dla&#160;inwestora, generalnego wykonawcy, zarządcy.',
        image: {
            src: 'activity2.jpg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'Dostarczona na najwyższym poziomie graficzna baza danych może być wykorzystywana i&#160;przetwarzana przez architektów, informatyków i&#160;Facility Management.',
        image: {
            src: 'activity3.jpg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
]

const Activity = props => {

    return (
        <>
            <div className='activities__container position-relative' id="activity">
            <div className="under-title under-title--big">O nas</div>
            <SideBar section={ 0 }/>
                <div className='container'>
                    <div className='activities position-relative'>
                        <h3 className='title'>Czym się zajmujemy</h3>
                        <p className='subtitle xl-normal'>
                            Wykorzystujemy najnowsze rozwiązania w&#160;oparciu o&#160;technologię BIM w&#160;gałęziach przemysłu i&#160;budownictwa do&#160;procesów inwestycyjnych.
                        </p>
                    </div>
                    <div className='activity__container'>
                        {activities.map((activity) => {
                            return (
                                <div className='activity' key={activity.text}>
                                    <div className='activity__left'>
                                        <Image src={activity.image.src}
                                            className='mx-auto shadow-xl'
                                            alt={activity.image.alt} />
                                    </div>
                                    <div className='activity__right xl-normal'>
                                    <p dangerouslySetInnerHTML={{__html: activity.text}}></p>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}


export default Activity;