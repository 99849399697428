import React from 'react';
import './styles/styles.scss';
import Accordion from './components/Accordion';
import SideBar from '../../components/sideBar/SideBar';
import { useStaticQuery, graphql } from "gatsby";


let panels = [
    {
        title: 'I-21/10S Model 3D przemysłowych linii transportowych, FR',
        subtitle: 'Rozbudowa linii transportowych. Wykonanie skanowania 3D&#160;oraz modelu istniejącej infrastruktury do&#160;koordynacji projektu i&#160;aktualizacja dokumentacji (Layoutów).',
        content: 'Realizacja zadania w&#160;funkcjonującym obiekcie. Usprawnienie pracy managerów i&#160;inżynierów podczas prac związanych z&#160;przebudową na&#160;etapie projektu, przygotowania i&#160;montażu.',
        image1: 'studies1-1.jpg',
        image2: 'studies1-2.jpg',
        image3: 'studies1-3.jpg',
        value1: '15000',
        value1description: 'm kw. obszaru taśm przesyłowych',
        value2: '1200',
        value2description: 'pozycji skanera  w funkcjonującym obiekcie',
        value3: '25 000',
        value3description: 'elementów modelu ',
        value4: '900',
        value4description: 'mb taśm przesyłowych',
    },
    {
        title: 'I-20/15S Koordynacja rozbudowy części zakładu produkcyjnego, PL',
        subtitle: 'Wykonanie aktualizacji istniejącej dokumentacji (Layoutów) w&#160;modelu 3D/BIM na&#160;potrzeby rozbudowy produkcji. ',
        content: 'Wsparcie klienta w&#160;obszarze możliwości technicznych przebudowy obiektu i&#160;zmiany układu urządzeń. Analiza wariantów rozbudowy w&#160;ujęciu procesów technologicznych. Wirtualna analiza wykonalności złożeń projektowych. Wskazanie kolizji pomiędzy istniejącymi elementami budynku i&#160;linii technologicznych, a&#160;nowoprojektowanymi. ',
        image1: 'studies2-1.jpg',
        image2: 'studies2-2.jpg',
        image3: 'studies2-3.jpg',
        value1: '3100',
        value1description: 'mb instalacji technologicznych',
        value2: '630',
        value2description: 'szt urządzeń i podzespołów linii technologicznych ',
        value3: '2240',
        value3description: 'elementów modelu',
        value4: '500',
        value4description: 'mb linii przesyłowych',
    },
    {
        title: 'L-21/4J Powykonawczy model BIM Parku Logistycznego, PL',
        subtitle: 'Wykonanie skanowania 3D oraz modelu powykonawczego BIM o&#160;łącznej powierzchni powyżej 110 tyś. m2.',
        content: ' Model zawiera elementy wszystkich branż, uzupełnione o&#160;dane z&#160;kart materiałowych i&#160;dokumentacji projektowej. Model powstający w&#160;oparciu o&#160;chmurę punktów, a&#160;także projekt powykonawczy wykorzystywany do&#160;weryfikacji wykonanej inwestycji na&#160;etapie pozwolenia na&#160;użytkowanie. Model używany do&#160;wspomagania systemów zarządzania budynkami (Facility Management).',
        image1: 'studies3-1.jpg',
        image2: 'studies3-2.jpg',
        image3: 'studies3-3.jpg',
        value1: '115 000',
        value1description: 'm kw. powierzchni / 9 000 m2 biur',
        value2: '5500',
        value2description: 'pozycji skanera na etapie realizacji',
        value3: '550',
        value3description: 'słupów żelbetowych  siatka 12x22m',
        value4: '2000',
        value4description: 'tryskaczy na hali',
    },
    {
        title: 'W-42/8P Powykonawczy model BIM Hali Magazynowej, PL',
        subtitle: 'Wykonanie skanowania 3D oraz modelu powykonawczego BIM o&#160;łącznej powierzchni powyżej 60 tyś. m2.',
        content: 'Wielobranżowy model wykonany w&#160;fazie sporządzania dokumentacji powykonawczej (As build) na&#160;podstawie chmur punktów. zawierający branże architektoniczną, konstrukcyjną, instalacje wodnokanalizacyjne, HVAC, instalację elektryczną, ppoż. Opracowanie modelu na&#160;tym etapie miało za&#160;zadanie weryfikację poprawności prac budowlanych w&#160;kontekście dokumentacji projektowej.',
        image1: 'studies4-1.jpg',
        image2: 'studies4-2.jpg',
        image3: 'studies4-3.jpg',
        value1: '65 000',
        value1description: 'm kw. powierzchni',
        value2: '14 000',
        value2description: 'stron dokumentacji',
        value3: '2300',
        value3description: 'pozycji skanera',
        value4: '8 000',
        value4description: 'm długości belek konstrukcyjnych',
    },
];


export default function CaseStudies() {
//     const data = useStaticQuery(graphql`
//         query {
//             allStrapiRealization {
//                 nodes {
//                 id
//                 image1 {
//                     url
//                 }
//                 image2 {
//                     url
//                 }
//                 image3 {
//                     url
//                 }
//                 subtitle
//                 title
//                 content
//                 value1
//                 value1description
//                 value2
//                 value2description
//                 value3
//                 value3description
//                 value4
//                 value4description
//                 }
//             }
//         }
// `);

    return (
        <>
            <div className='caseStudies position-relative' id='caseStudies'>
                <SideBar section={4} />
                <div className='container caseStudies__container'>
                    <h3 className='title white'>Case studies</h3>
                    <Accordion panels={panels} />
                </div>
            </div>
        </>
    )
}
