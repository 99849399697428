import React, { Component } from 'react';
import './styles/styles.scss';
import Image from '../../components/image/Image'
import SideBar from '../../components/sideBar/SideBar';
import backgroundVideo from '../../images/videoBackground.mp4'

class Pill extends Component {

    componentDidMount() {
        document.querySelector('.fullscreen-bg__video').playbackRate = 0.8;
    }

    render() {

        return (
            <>
                <div className='position-relative'>
                    <div className='videoSection'>
                        <video
                            autoPlay
                            muted
                            loop
                            playsinline
                            className='fullscreen-bg__video'
                            src={backgroundVideo}>
                            <source src={backgroundVideo} type="video/mp4" />
                        </video>
                    </div>
                    <div className='pill position-relative' id='pill'>
                        <SideBar section={1} />
                        <div className='container pill__container'>
                            <div className='pill__section'>
                                <div className='pill__left'>
                                    <Image src='big-logo5.svg'
                                        className='mx-auto shadow-xl'
                                        alt='logo' />
                                </div>
                                <div className='pill__right'>
                                    <p className='title'>
                                        Przygotowujemy wirtualne modele bazując na&#160;różnorodnych danych, od&#160;chmur punktów po&#160;dokumentację techniczną i&#160;projektową.
                                    </p>
                                    <span className='subtitle xl-small'>
                                        Stosujemy uniwersalne narzędzia i&#160;rowiąznia do modelowania w różnych standardach i&#160;środowiskach pracy. Dzielimy się&#160;wiedzą i&#160;doświadczeniem z&#160;naszymi partnerami.
                                    </span>
                                    <span className='subtitle xl-small'>
                                        W naszej pracy wykorzystujemy technologię BIM (z ang. Building Information Modeling / Management). Usługi, które&#160;oferujemy mają zastosowanie w&#160;każdym etapie procesu inwestycyjnego.
                                    </span>
                                    <div className='pills'>
                                        <div className='pill__item'>
                                            <Image src='pill1.svg'
                                                className='mx-auto shadow-xl'
                                                alt='icon' />
                                            <p className='xl-small'>
                                                Weryfikujemy istniejące dane w&#160;dokumentacji budowlanej, wykonawczej i&#160;powykonawczej.
                                            </p>
                                        </div>
                                        <div className='pill__item'>
                                            <Image src='pill2.svg'
                                                className='mx-auto shadow-xl'
                                                alt='icon' />
                                            <p className='xl-small'>
                                                Sprawdzamy zgodność stanu faktycznego inwestycji na&#160;bazie danych ze&#160;skanowania 3D.
                                            </p>
                                        </div>
                                        <div className='pill__item'>
                                            <Image src='pill3.svg'
                                                className='mx-auto shadow-xl'
                                                alt='icon' />
                                            <p className='xl-small'>
                                                W przyjazny sposób systematyzujemy dane o&#160;budynku do&#160;analiz przestrzennych i&#160;finansowych.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Pill;